import React from "react";

const saveMoney = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="137"
            height="143"
            fill="none"
            viewBox="0 0 137 143"
        >
            <g clipPath="url(#clip0_107_1280)">
                <path
                    fill="#E0E3E4"
                    d="M68.419 142.75c37.786 0 68.418-2.617 68.418-5.845 0-3.228-30.632-5.845-68.418-5.845C30.632 131.06 0 133.677 0 136.905c0 3.228 30.632 5.845 68.419 5.845z"
                ></path>
                <path
                    fill="#005E85"
                    d="M64.444 61.949c22.6 0 40.92-1.823 40.92-4.073 0-2.25-18.32-4.073-40.92-4.073-22.599 0-40.92 1.824-40.92 4.073 0 2.25 18.321 4.073 40.92 4.073z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M75.625 54.91H33.971v10.767h41.654V54.909z"
                ></path>
                <path
                    fill="#D98753"
                    d="M74.087 54.91H58.632v10.767h15.455V54.909z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M57.188 54.91h-6.476v10.767h6.476V54.909z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M41.137 54.91h-3.554v10.767h3.554V54.909z"
                ></path>
                <path
                    fill="#D67B2F"
                    d="M75.625 64.078H33.971v1.599h41.654v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M73.065 44.433H31.411V55.2h41.654V44.433z"
                ></path>
                <path
                    fill="#D98753"
                    d="M71.527 44.433H56.072V55.2h15.455V44.433z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M54.628 44.433h-6.476V55.2h6.476V44.433z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M38.577 44.433h-3.554V55.2h3.554V44.433z"
                ></path>
                <path
                    fill="#A36136"
                    d="M73.065 53.601H31.411v1.6h41.654v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M75.893 33.956H34.24v10.768h41.654V33.956z"
                ></path>
                <path
                    fill="#D98753"
                    d="M74.355 33.956H58.9v10.768h15.455V33.956z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M57.458 33.956H50.98v10.768h6.477V33.956z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M41.404 33.956h-3.553v10.768h3.553V33.956z"
                ></path>
                <path
                    fill="#A36136"
                    d="M75.893 43.123H34.24v1.6h41.654v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M100.34 52.753H63.603V63.52h36.737V52.754z"
                ></path>
                <path
                    fill="#D98753"
                    d="M98.985 52.753H85.354V63.52h13.631V52.754z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M84.08 52.753h-5.71V63.52h5.71V52.754z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M69.923 52.753H66.79V63.52h3.134V52.754z"
                ></path>
                <path
                    fill="#D67B2F"
                    d="M100.34 61.92H63.603v1.6h36.737v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M73.125 23.428H31.471v10.768h41.654V23.428z"
                ></path>
                <path
                    fill="#D98753"
                    d="M71.587 23.428H56.132v10.768h15.455V23.428z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M54.688 23.428h-6.476v10.768h6.476V23.428z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M38.636 23.428h-3.553v10.768h3.553V23.428z"
                ></path>
                <path
                    fill="#A36136"
                    d="M73.125 32.596H31.471v1.599h41.654v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M75.747 12.645H34.093v10.767h41.654V12.645z"
                ></path>
                <path
                    fill="#D98753"
                    d="M74.209 12.645H58.754v10.767h15.455V12.645z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M57.31 12.645h-6.476v10.767h6.476V12.645z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M41.259 12.645h-3.554v10.767h3.554V12.645z"
                ></path>
                <path
                    fill="#A36136"
                    d="M75.747 21.813H34.093v1.6h41.654v-1.6z"
                ></path>
                <path
                    fill="#F9A56E"
                    d="M72.578 1.826H30.923v10.768h41.655V1.826z"
                ></path>
                <path
                    fill="#D98753"
                    d="M71.04 1.826H55.584v10.768H71.04V1.826z"
                ></path>
                <path
                    fill="#E68F58"
                    d="M54.14 1.826h-6.475v10.768h6.476V1.826z"
                ></path>
                <path
                    fill="#FFC18F"
                    d="M38.09 1.826h-3.555v10.768h3.554V1.826z"
                ></path>
                <path
                    fill="#A36136"
                    d="M72.578 10.995H30.923v1.599h41.655v-1.6z"
                ></path>
                <path
                    fill="#fff"
                    d="M84.974 112.494c.75-14.36 24.584-26.778 20.39-54.589h-.005c-.157 2.235-18.415 4.044-40.916 4.044-22.502 0-40.76-1.807-40.917-4.044h-.03c.1 4.482.87 11.357-.37 13.345-3.858 6.184-6.967 9.067-8.036 9.799-2.854 1.95-2.954 6.833 1.72 8.584 3.648 1.368 3.216.772 3.232 3.516.01 1.695-.425 2.477-.927 3.41-.64 1.191-.9 1.376-.303 2.71.61 1.363 2.677 2.25 2.677 2.25s-2.367 1.667.053 5.033c.533.74 1.58 1.141 1.72 1.73.744 3.147.517 3.018.504 4.202-.017 1.518.21 7.503 7.694 8.799 1.397.242 7.31.201 13.24-.124 1.048 7.638.956 9.914 2.646 16.409l39.307.27c-1.432-10.564-2.059-14.324-1.682-25.342l.003-.002z"
                ></path>
                <path
                    fill="#002A3A"
                    d="M44.703 121.44a.281.281 0 01-.03-.561l7.876-.884a.28.28 0 01.311.249.283.283 0 01-.249.312l-7.876.883-.032.001z"
                ></path>
                <path
                    fill="#000"
                    d="M96.99 1.729s1.674 3.813 6.483 5.27c4.81 1.457 12.97 7.504 3.498 14.133-9.473 6.63-18.656-3.556-19.674-9.18-.911-5.027 3.156-10.632 3.156-10.632l6.537.407v.002z"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M99.292 52.235l-21.23.203-.155-5.428 14.268-7.232 8.325-1.735 2.605-1.238c3.814 4.796 2.058 15.395-3.81 15.43h-.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M87.401 50.244l-19.89 1.182-4.085-6.32 6.34-3.572 18.919-6.31 3.306 3.858c3.828 4.465 1.297 10.617-4.59 11.162z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M74.208 47.036s-3.542-.352-8.994 9.24c-2.988 5.257-15.29 16.349-15.29 16.349l-1.393-2.098L63.054 43.76c1.159-1.925 3.35-3.287 5.579-3.47l6.23-.51-.655 7.254v.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#14284F"
                    fillRule="evenodd"
                    d="M48.951 69.79l-2.737 1.23c-.58.328-3.39.573-3.39.573-.387.485.057.89.472 1.061l7.745 1.623s1.866.042 1.081-3.755c-1.545.294-3.051.418-3.17-.734v.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M83.174 47.344s-3.556.17-7.546 10.457c-2.186 5.637-12.681 17.89-12.681 17.89l-1.259-1.925 9.972-28.03c.864-2.073 2.832-3.74 5.01-4.248l6.089-1.416.413 7.274.002-.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#14284F"
                    fillRule="evenodd"
                    d="M62.213 72.152l-2.597 1.505c-.543.385-3.314.918-3.314.918-.335.52.147.877.578 1.005l7.871.82s1.86-.148.69-3.843c-1.508.45-2.993.727-3.23-.406l.002.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1C5369"
                    fillRule="evenodd"
                    d="M60.326 68.217l7.659 2.091s2.525-2.814 4.17-4.91c3.298-4.197 7.2-12.043 7.373-12.193.173-.148 20.02-.349 20.993-.46 2.529-.288 7.999-2.851 3.224-16.004l-23.987 5.732s-5.694.69-6.96 2.658c-.82 1.273-1.638 2.488-2.732 4.4-5.78 10.091-9.74 18.685-9.74 18.685v.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1C5369"
                    fillRule="evenodd"
                    d="M50.028 64.786l6.443 2.53s11.444-11.703 13.161-14.364c1.717-2.66 30.173-3.504 30.173-3.504l-1.558-12.96-16.619.317s-9.357 2.491-10.2 2.736c-.844.244-6.27-.996-9.73 5.337-3.46 6.332-11.669 19.907-11.669 19.907v.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M79.135 26.905l4.641-12.18c1.073-2.953 4.024-4.15 6.203-2.514.858.645 1.101 2.03.547 3.114L83.62 28.811l-4.486-1.905.001-.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#002A3A"
                    fillRule="evenodd"
                    d="M87.15 11.546l4.118-1.434 3.535-.414 1.733.069 6.587 2.297a4.841 4.841 0 012.399 6.01l-1.612 7.538c1.713 5.687 2.651 7.103 2.355 12.955-8.606 1.495-23.263 0-23.949-.783.498-11.713 1.86-20.333 4.832-26.238h.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M91.691 6.38l.606 4.548c.073.548.434 1.015.943 1.22.51.204 1.088.114 1.513-.234l.405-.33a1.978 1.978 0 00.668-1.99l-1.28-5.338L91.69 6.38z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#873B24"
                    fillRule="evenodd"
                    d="M91.863 8.14l.313 1.477c1.055-.355 1.611-1.343 2.062-2.465l-2.375.986v.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    d="M91.354 10.12s-2.071 5.127 1.448 5.352c3.519.226 5.445-5.103 5.445-5.103l-2.57-1.142-4.323.893z"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M95.913 3.297a1.332 1.332 0 00-1.123-1.504l-2.51-.357c-.72-.102-1.276.43-1.487 1.134-.248.83-.362 2.612-.288 3.914.041.735.51 1.473 1.232 1.574.77.11 3.176-.304 3.59-1.663.316-1.034.438-2.027.585-3.1l.001.002zM105.637 30.283l-4.977-11.565c-1.244-2.772.075-5.4 2.771-5.525 1.063-.05 2.188.805 2.528 1.918l4.231 13.861-4.553 1.31v.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M110.163 28.652c.511 2.366-.42 4.611-3.076 5.517l-13.611 1.689.73-2.762 9.896-2.898 6.063-1.546h-.002zM80.898 25.384c-1.656 1.704-2.214 4.06-.806 6.202l3.377 4.903L86 35.225l-2.856-6.06-2.245-3.78v-.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E99E88"
                    d="M94.205 33.096l-2.224 1.14a2.093 2.093 0 00-.98 1.069l-.763 1.873c-.267.653.203 1.369.908 1.388l2.712-1.59c.599-.351 1.074-.952 1.332-1.68l.428-1.215-1.415-.986.002.001zM83.903 34.113l2.405.674c.48.135.898.438 1.175.852l1.12 1.684c.39.587.075 1.382-.612 1.541l-2.976-1.018c-.657-.225-1.243-.718-1.64-1.381l-.663-1.105 1.19-1.248v.001z"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M96.581 39.542H76.345v-1.53H95.05a1.53 1.53 0 011.531 1.53z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M86.486 39.542H67.31L61.525 26.65H80.26c.268 0 .51.157.619.4l5.605 12.492h.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1086B7"
                    fillRule="evenodd"
                    d="M85.742 39.542H66.567l-5.52-12.298a.421.421 0 01.385-.594h18.252c.166 0 .316.098.384.249l5.674 12.643z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M73.959 33.178c.228.804-.395 1.456-1.39 1.456-.995 0-1.988-.652-2.216-1.456-.229-.805.394-1.457 1.39-1.457.995 0 1.987.652 2.216 1.457z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    d="M65.962 57.227a.281.281 0 01-.247-.418l6.193-11.215c.09-.168 2.332-4.227 11.934-4.575a.283.283 0 01.02.565c-9.235.333-11.436 4.24-11.457 4.279l-6.196 11.219a.282.282 0 01-.247.145z"
                ></path>
                <path
                    fill="#002A3A"
                    fillRule="evenodd"
                    d="M99.422 16.627l3.22 8.759 7.21-2.604c-1.384-1.503-2.815-8.69-6.729-10.716-4.305-2.228-3.703 4.563-3.703 4.563l.002-.002zM87.15 11.546s-2.78.192-4.247 4.994-2.76 5.78-2.76 5.78l5.263 2.131 4.044-9.953-2.299-2.952h-.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#8B939C"
                    d="M84.082 23.79a.145.145 0 01-.14-.185c.051-.182 1.25-4.463 2.11-6.153.831-1.634.83-2.511.83-2.52 0-.08.062-.145.142-.147h.003c.079 0 .144.063.146.143 0 .038.008.947-.862 2.657-.847 1.664-2.077 6.054-2.09 6.098a.144.144 0 01-.14.106zM101.886 23.1a.144.144 0 01-.136-.094l-2.733-7.074a.158.158 0 01-.01-.053v-.948a.146.146 0 01.291 0v.922l2.724 7.049a.144.144 0 01-.083.187.129.129 0 01-.052.01h-.001z"
                ></path>
                <path
                    fill="#000"
                    d="M93.46.786s-2.125 3.365-3.49 3.553c0 0-.347-2.385.82-3.437 1.855-1.676 2.67-.116 2.67-.116z"
                ></path>
                <path
                    fill="#000"
                    d="M92.777 1.545s1.249 3.177 2.474 3.45c1.225.274 1.85-2.26 1.771-3.04-.078-.78-1.198-1.833-2.977-1.95-1.78-.116-1.27 1.54-1.27 1.54h.002z"
                ></path>
                <path
                    fill="#E99E88"
                    fillRule="evenodd"
                    d="M96.316 6.304c-.28.534-.861.777-1.298.54-.436-.233-.564-.856-.285-1.39.28-.534.861-.777 1.298-.542.437.236.565.858.285 1.392z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M91.117 5.864l1.324.214s-.127 1.064-.834.867c-.707-.196-.49-1.081-.49-1.081z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E68F58"
                    d="M97.937 139.804c10.502-2.554 16.945-13.137 14.391-23.638-2.554-10.501-13.139-16.944-23.641-14.389-10.503 2.554-16.946 13.137-14.391 23.638 2.554 10.501 13.138 16.943 23.64 14.389z"
                ></path>
                <path
                    fill="#FFCCAB"
                    d="M93.698 139.823c10.52-2.477 17.041-13.013 14.564-23.532-2.478-10.519-13.015-17.04-23.536-14.562-10.52 2.477-17.041 13.013-14.564 23.532 2.478 10.519 13.015 17.039 23.536 14.562z"
                ></path>
                <path
                    fill="#F57F26"
                    d="M92.646 133.442c7.057-1.754 11.355-8.896 9.601-15.951-1.754-7.056-8.897-11.354-15.953-9.6-7.057 1.754-11.355 8.895-9.6 15.951 1.753 7.056 8.896 11.354 15.952 9.6z"
                ></path>
                <path
                    fill="#fff"
                    d="M91.02 126.977c-1.353.368-2.905.203-4.061-.271l.34-1.455c1.162.426 2.432.649 3.68.182 1.51-.566 1.953-1.687 1.478-2.954-1.24-3.309-6.671-.458-8.164-4.441-.681-1.817.012-3.569 1.691-4.5l-.773-2.064 1.225-.46.759 2.023c1.36-.348 2.456-.035 3.482.466l-.524 1.337c-1.032-.451-1.825-.643-2.928-.23-1.245.467-1.752 1.542-1.308 2.727 1.11 2.961 6.504.008 8.15 4.4.727 1.94.007 3.748-1.834 4.741l.773 2.063-1.226.46-.758-2.023-.001-.001z"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M74.947 114.655c.032 1.067-.079 2.335-.63 3.223-.234.378 0 .87.438.952.851.158 2.007.486 3.031 1.159a.637.637 0 00.994-.551 6.98 6.98 0 01.56-3.015.635.635 0 00-.483-.875c-.724-.113-1.788-.448-2.843-1.389a.638.638 0 00-1.066.496h-.001z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#A36136"
                    d="M91.238 133.929a.327.327 0 01-.045-.652c.027-.004 2.86-.42 5.614-2.254 3.667-2.441 5.473-6.115 5.37-10.917a.329.329 0 01.32-.335c.175-.006.33.14.335.32.135 6.292-3.015 9.722-5.683 11.491-2.877 1.909-5.744 2.327-5.865 2.344a.236.236 0 01-.045.003h-.001zM72.265 130.901a.326.326 0 01-.288-.171c-.092-.171-2.247-4.223-2.44-9.636-.113-3.188.487-6.177 1.783-8.882 1.619-3.377 4.331-6.314 8.061-8.729a.328.328 0 01.355.55c-3.626 2.349-6.26 5.196-7.826 8.463-1.25 2.609-1.83 5.494-1.72 8.575.187 5.261 2.34 9.308 2.362 9.349a.327.327 0 01-.289.483l.002-.002z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_107_1280">
                    <path fill="#fff" d="M0 0H136.837V142.75H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export default saveMoney;
