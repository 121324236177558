import FeatureList from "@components/shared/featureList";
import { ReactElement } from "react";
import colors from "@styles/variables/homewarrantyVariables.module.scss";
import SaveMoney from "./icons/saveMoney";
import PeaceOfMindIcon from "./icons/PeaceOfMindIcon";
import SaveValueIcon from "./icons/SaveValueIcon";
import styles from "./styles.module.scss";

const benefitsList = [
    {
        title: " Save Money",
        description:
            "One major car repair can cost more than the warranty as a whole. An extended car warranty will cover these unexpected costs, saving you money in the long run.",
        icon: <SaveMoney />,
    },
    {
        title: "Save Peace of Mind",
        description:
            "Even well-maintained cars require repairs. Prevent surprises. Auto warranties make driving stress-free. You've already put a lot of money into your vehicle; keep it safe.",
        icon: <PeaceOfMindIcon />,
    },
    {
        title: "Save Value",
        description:
            "An extended warranty shows that the car was well cared for and had no hidden problems. The car's resale value rises as a result. A car warranty gives prospective purchasers comfort.",
        icon: <SaveValueIcon />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            mainTitle="What a Great Car Warranty Can Do for You:"
            classes={{
                mainTitleClasses: `font-bold text-2xl text-center  lg:text-3xl 2xl:text-4xl mb-14 mt-14 md:mt-24 `,
                innerSectionClasses: "lg:flex flex-wrap justify-between",
                oneBoxClasses: `text-center mb-12 lg:mb-20 w-full lg:w-[30%] py-16 ${styles.item} !bg-[#F2F2F2]`,
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-2xl my-6",
                descriptionClasses:
                    "font-light text-base  text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainTitleColor: colors.mainColor,
                titleColor: colors.mainColor,
                descriptionColor: colors.paragraphColor,
            }}
        />
    );
}
