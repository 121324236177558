import React from "react";

function SaveValueIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="138"
            height="143"
            fill="none"
            viewBox="0 0 138 143"
        >
            <path
                fill="#1D5369"
                d="M45.08 44.462s-2.997-18.89 20.11-19.564c0 0-1.017 16.739-20.11 19.564z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M63.023 26.77a.175.175 0 01-.01.246L48.56 40.443a.174.174 0 01-.238-.255L62.776 26.76a.175.175 0 01.247.01z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M61.481 31.213a.174.174 0 01-.133.208l-3.842.842a.175.175 0 01-.074-.341l3.841-.842a.175.175 0 01.208.133z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M58.551 28.063a.175.175 0 01.118.217l-1.033 3.513a.175.175 0 01-.334-.098l1.033-3.514a.175.175 0 01.216-.118zM56.397 36.855a.174.174 0 01-.146.199l-4.901.756a.174.174 0 11-.054-.345l4.902-.756a.175.175 0 01.199.146z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M52.347 32.981c.094.021.154.114.133.208l-.987 4.486a.174.174 0 01-.34-.075l.986-4.486a.174.174 0 01.208-.133z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E0E3E4"
                d="M47.723 142.651c26.357 0 47.723-1.703 47.723-3.804s-21.366-3.804-47.723-3.804S0 136.746 0 138.847s21.366 3.804 47.723 3.804z"
            ></path>
            <path
                fill="#fff"
                d="M79.508 122.485l-.006-51.87h-.01c.177-4.465-2.948-8.97-9.386-12.388-12.482-6.628-32.656-6.66-45.06-.075-5.719 3.036-8.78 6.95-9.204 10.935h-.005v.035a9.041 9.041 0 000 1.873v51.568c0 8.942 7.986 16.195 17.852 16.22l27.872.066c9.903.024 17.946-7.244 17.946-16.218v-.146z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M79.425 83.469c.118 0 .213.095.213.213l.006 39.168c0 9.112-8.159 16.456-18.16 16.432l-19.54-.046a.214.214 0 01.001-.428l19.54.047c9.805.023 17.732-7.168 17.732-16.005l-.006-39.168c0-.118.096-.213.214-.213z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M69.066 51.195H24.501a3.842 3.842 0 000 7.683h44.565a3.842 3.842 0 100-7.683z"
            ></path>
            <path
                fill="#F9A56E"
                d="M63.755 129.499H32.277v7.161h31.478v-7.161z"
            ></path>
            <path
                fill="#D98753"
                d="M62.593 129.499h-11.68v7.161h11.68v-7.161z"
            ></path>
            <path
                fill="#E68F58"
                d="M49.823 129.499H44.93v7.161h4.893v-7.161z"
            ></path>
            <path
                fill="#F9B589"
                d="M37.694 129.499h-2.686v7.161h2.686v-7.161z"
            ></path>
            <path
                fill="#FFEADA"
                d="M63.755 135.597H32.277v1.063h31.478v-1.063z"
            ></path>
            <path
                fill="#F9A56E"
                d="M63.755 122.53H32.277v7.162h31.478v-7.162z"
            ></path>
            <path
                fill="#D98753"
                d="M62.593 122.53h-11.68v7.162h11.68v-7.162z"
            ></path>
            <path
                fill="#E68F58"
                d="M49.823 122.53H44.93v7.162h4.893v-7.162z"
            ></path>
            <path
                fill="#F9B589"
                d="M37.694 122.53h-2.686v7.162h2.686v-7.162z"
            ></path>
            <path
                fill="#FFEADA"
                d="M63.755 128.628H32.277v1.064h31.478v-1.064z"
            ></path>
            <path
                fill="#F9A56E"
                d="M61.655 115.561H30.177v7.162h31.478v-7.162z"
            ></path>
            <path
                fill="#D98753"
                d="M60.492 115.561H48.813v7.162h11.679v-7.162z"
            ></path>
            <path
                fill="#E68F58"
                d="M47.723 115.561H42.83v7.162h4.893v-7.162z"
            ></path>
            <path
                fill="#F9B589"
                d="M35.594 115.561h-2.686v7.162h2.686v-7.162z"
            ></path>
            <path
                fill="#FFEADA"
                d="M61.655 121.659H30.177v1.064h31.478v-1.064z"
            ></path>
            <path
                fill="#F9A56E"
                d="M63.035 108.364H31.557v7.161h31.478v-7.161z"
            ></path>
            <path
                fill="#D98753"
                d="M61.872 108.364h-11.68v7.161h11.68v-7.161z"
            ></path>
            <path
                fill="#E68F58"
                d="M49.103 108.364h-4.894v7.161h4.894v-7.161z"
            ></path>
            <path
                fill="#F9B589"
                d="M36.974 108.364h-2.686v7.161h2.686v-7.161z"
            ></path>
            <path
                fill="#FFEADA"
                d="M63.035 114.462H31.557v1.063h31.478v-1.063z"
            ></path>
            <path
                fill="#F9A56E"
                d="M63.035 101.395H31.557v7.161h31.478v-7.161z"
            ></path>
            <path
                fill="#D98753"
                d="M61.872 101.395h-11.68v7.161h11.68v-7.161z"
            ></path>
            <path
                fill="#E68F58"
                d="M49.103 101.395h-4.894v7.161h4.894v-7.161z"
            ></path>
            <path
                fill="#F9B589"
                d="M36.974 101.395h-2.686v7.161h2.686v-7.161z"
            ></path>
            <path
                fill="#FFEADA"
                d="M63.035 107.493H31.557v1.063h31.478v-1.063z"
            ></path>
            <path
                fill="#F9A56E"
                d="M64.759 94.426H33.28v7.162H64.76v-7.162z"
            ></path>
            <path
                fill="#D98753"
                d="M63.596 94.426H51.918v7.162h11.678v-7.162z"
            ></path>
            <path
                fill="#E68F58"
                d="M50.827 94.426h-4.893v7.162h4.893v-7.162z"
            ></path>
            <path
                fill="#F9B589"
                d="M38.697 94.426h-2.686v7.162h2.686v-7.162z"
            ></path>
            <path
                fill="#FFEADA"
                d="M64.759 100.524H33.28v1.064H64.76v-1.064z"
            ></path>
            <path
                fill="#F5F8FF"
                d="M32.306 71.363a7.416 7.416 0 10-14.78-1.23 7.416 7.416 0 0014.78 1.23zM36.369 64.633a3.176 3.176 0 10-2.038-6.016 3.176 3.176 0 002.038 6.016z"
                opacity="0.71"
            ></path>
            <path
                fill="#005E85"
                fillRule="evenodd"
                d="M42.55 26.222a.96.96 0 011.136.745c1.657 7.98 2.967 18.094 3.684 24.255a.96.96 0 11-1.907.222c-.716-6.153-2.019-16.194-3.658-24.086a.96.96 0 01.745-1.136z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#005E85"
                fillRule="evenodd"
                d="M46.47 51.204a.96.96 0 011.065.843c.95 8.152 1.152 13.687 1.114 17.197a47.718 47.718 0 01-.173 3.826 20.041 20.041 0 01-.138 1.149l-.011.063-.003.019-.002.006v.003l-.943-.179.943.18a.96.96 0 01-1.887-.358v-.002l.001-.004.007-.04.028-.186c.024-.173.058-.446.093-.828.07-.763.147-1.961.165-3.67.037-3.416-.16-8.868-1.101-16.954a.96.96 0 01.842-1.065z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D5369"
                d="M45.273 44.013s-9.306 4.112-24.71-13.22c0 0 22.943-4.449 24.71 13.22z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M24.944 32.202a.175.175 0 01.235-.079l16.483 8.246a.175.175 0 01-.156.313l-16.484-8.245a.175.175 0 01-.078-.235z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M28.525 31.62a.175.175 0 01.24.062l1.903 3.211-3.15 1.11a.175.175 0 01-.116-.33l2.747-.968-1.685-2.845a.175.175 0 01.061-.24zM34.429 34.494a.175.175 0 01.242.053l2.375 3.687-4.176.818a.175.175 0 01-.067-.344l3.669-.718-2.096-3.254a.175.175 0 01.053-.242zM44.208 41.105a.213.213 0 01.242.18l.983 6.725a.214.214 0 01-.423.062l-.983-6.725a.213.213 0 01.18-.242z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFCCAB"
                d="M56.246 15.245C56.34 7.115 49.822.448 41.691.355c-8.13-.091-14.797 6.425-14.89 14.556-.091 8.131 6.425 14.797 14.556 14.89 8.131.092 14.797-6.425 14.89-14.556z"
            ></path>
            <path
                fill="#F57F26"
                d="M51.636 15.263c.125-5.47-4.207-10.004-9.676-10.129-5.468-.125-10.003 4.207-10.128 9.676s4.207 10.003 9.675 10.128c5.47.125 10.004-4.207 10.129-9.675z"
            ></path>
            <path
                fill="#fff"
                d="M41.64 19.932c-1.055.006-2.152-.413-2.902-.982l.528-.992c.762.535 1.644.942 2.64.843 1.209-.12 1.747-.85 1.645-1.863-.263-2.646-4.763-1.621-5.079-4.806-.145-1.453.698-2.592 2.098-2.946l-.165-1.65.98-.098.16 1.617c1.057.01 1.794.448 2.444 1.011l-.639.872c-.663-.528-1.203-.82-2.084-.733-.996.1-1.572.783-1.478 1.73.236 2.368 4.727 1.262 5.076 4.773.154 1.551-.718 2.727-2.25 3.093l.166 1.65-.98.098-.161-1.617h.001z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M32.332 7.868c-.183.782-.508 1.683-1.08 2.223a.478.478 0 00.133.776c.589.28 1.365.741 1.98 1.429a.479.479 0 00.829-.21c.14-.611.422-1.364.99-2.085A.477.477 0 0035 9.27c-.506-.222-1.214-.671-1.8-1.56a.48.48 0 00-.871.156l.002.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFEADA"
                fillRule="evenodd"
                d="M51.732 13.164a.191.191 0 01.208.173c.438 4.936-1.733 7.915-4.017 9.652a13.115 13.115 0 01-4.553 2.202 4.563 4.563 0 01-.103.024l-.006.002h-.002l-.04-.187.04.187a.191.191 0 01-.079-.375m0 0h.006l.018-.005.075-.018c.067-.016.165-.041.29-.076.25-.072.608-.184 1.035-.35.856-.33 1.986-.87 3.088-1.709 2.197-1.671 4.29-4.53 3.866-9.313a.192.192 0 01.174-.207M32.72 3.135l-.125-.146a.191.191 0 10.25.29l.002-.002.01-.008a4.25 4.25 0 01.217-.17c.153-.117.38-.281.675-.472.59-.383 1.45-.872 2.521-1.296C38.414.484 41.406-.1 44.813.94a.191.191 0 00.112-.366C41.414-.5 38.331.105 36.13.975c-1.1.435-1.982.937-2.589 1.33a11.706 11.706 0 00-.881.63l-.048.04-.012.01-.004.003v.001l.124.146z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F9A56E"
                d="M62.244 87.925H30.767v7.162h31.477v-7.162z"
            ></path>
            <path
                fill="#D98753"
                d="M61.082 87.925h-11.68v7.162h11.68v-7.162z"
            ></path>
            <path
                fill="#E68F58"
                d="M48.311 87.925h-4.893v7.162h4.893v-7.162z"
            ></path>
            <path
                fill="#F9B589"
                d="M36.182 87.925h-2.686v7.162h2.686v-7.162z"
            ></path>
            <path
                fill="#FFEADA"
                d="M62.244 94.023H30.767v1.064h31.477v-1.064z"
            ></path>
            <path
                fill="#F9A56E"
                d="M62.244 80.957H30.767v7.161h31.477v-7.161z"
            ></path>
            <path
                fill="#D98753"
                d="M61.082 80.957h-11.68v7.161h11.68v-7.161z"
            ></path>
            <path
                fill="#E68F58"
                d="M48.311 80.957h-4.893v7.161h4.893v-7.161z"
            ></path>
            <path
                fill="#F9B589"
                d="M36.182 80.957h-2.686v7.161h2.686v-7.161z"
            ></path>
            <path
                fill="#FFEADA"
                d="M62.244 87.055H30.767v1.063h31.477v-1.063z"
            ></path>
            <path
                fill="#F9A56E"
                d="M63.969 73.988H32.49v7.161H63.97v-7.161z"
            ></path>
            <path
                fill="#D98753"
                d="M62.806 73.988H51.127v7.161h11.679v-7.161z"
            ></path>
            <path
                fill="#E68F58"
                d="M50.035 73.988h-4.893v7.161h4.893v-7.161z"
            ></path>
            <path
                fill="#F9B589"
                d="M37.906 73.988H35.22v7.161h2.686v-7.161z"
            ></path>
            <path
                fill="#FFEADA"
                d="M63.969 80.086H32.49v1.063H63.97v-1.063z"
            ></path>
            <path
                fill="#2D4973"
                fillRule="evenodd"
                d="M52.498 58.226c0-.118.096-.214.214-.214h13.235a.214.214 0 110 .427H52.712a.214.214 0 01-.214-.213zM22.962 58.226c0-.118.096-.214.213-.214h4.189a.214.214 0 110 .427h-4.189a.214.214 0 01-.213-.213z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#005E85"
                fillRule="evenodd"
                d="M62.225 42.279s-10.624-.595-12.907 8.886l8.885-.052s-.93-4.938 4.525-7.183l-.502-1.651h-.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M119.739 79.396c1.759-.442 3.492.284 4.292 2.422l1.88 10.988-2.112-.557-2.626-7.968-1.434-4.885z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                d="M123.799 92.248l.957 1.783c.191.358.493.634.85.78l1.445.594c.503.207 1.023-.18 1.005-.75l-1.319-2.175a2.547 2.547 0 00-1.325-1.056l-.934-.332-.679 1.156z"
            ></path>
            <path
                fill="#002A3A"
                fillRule="evenodd"
                d="M112.876 66.652c2.667.43 10.537 11.815 10.812 13.008.309 1.342 2.363 9.086 2.363 9.086l-3.924.61s-2.616-6.437-3.143-6.934c-.527-.498-7.817-7.469-7.817-7.469l1.709-8.302v.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M92.856 60.617c-1.868.44-3.688-.354-4.5-2.627L86.54 46.343l2.224.623 2.651 8.465 1.44 5.186z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                d="M88.764 46.966l-.985-1.902a1.815 1.815 0 00-.886-.838l-1.517-.65c-.529-.227-1.085.175-1.074.777l1.36 2.321c.3.511.795.919 1.384 1.138l.982.366.736-1.212z"
            ></path>
            <path
                fill="#002A3A"
                fillRule="evenodd"
                d="M99.911 74.198c-2.812-.496-10.954-12.656-11.226-13.921-.307-1.424-2.355-9.644-2.355-9.644l4.157-.583s2.667 6.846 3.215 7.38c.549.533 8.147 8.018 8.147 8.018l-1.937 8.75z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M102.024 60.16s-1.217-1.537.576-2.952c1.793-1.414 1.783 2.233 1.724 2.276-.058.043-2.3.676-2.3.676z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M107.077 60.762s-.369 1.847-1.776 1.78c-1.408-.069-1.109-4.112-1.109-4.112l2.885 2.332z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M102.597 61.355l-.044 3.715c-.006.448.262.861.699 1.08.438.22.977.212 1.411-.022l.412-.22c.596-.32.935-.913.87-1.525l-.468-4.42-2.881 1.393h.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#3D3D3D"
                fillRule="evenodd"
                d="M102.448 62.647l.12 1.149 1.292-.98-1.412-.169z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M106.252 59.32a1.078 1.078 0 00-.758-1.319l-1.981-.528c-.569-.152-1.066.221-1.303.768-.281.643-.543 2.063-.61 3.118-.037.594.268 1.232.837 1.384.608.162 2.582.062 3.047-.99.354-.8.547-1.587.769-2.435l-.001.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M107.341 61.146s.539-2.235-.257-3.177c-1.069-1.266-2.599-2.055-4.485-.761 0 0-1.02.489 2.04 1.207.388.09-.365 1.92.118 2.133.651.289 1.493.506 2.583.598h.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M106.285 61.775c-.277.402-.767.54-1.097.31-.329-.23-.371-.743-.094-1.145s.767-.54 1.096-.311c.329.23.371.744.095 1.146z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EBEEF7"
                d="M114.54 142.755c12.78 0 23.14-1.139 23.14-2.545 0-1.405-10.36-2.544-23.14-2.544-12.781 0-23.141 1.139-23.141 2.544 0 1.406 10.36 2.545 23.141 2.545z"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M104.481 137.674s2.561-16.409-.55-22.902l1.882-22.26-10.337.715 6.118 44.604 2.887-.157z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M101.485 137.029l-1.496 1.5c-.406.372-2.611 1.091-2.611 1.091-.223.46.204.702.567.763l6.493-.137s1.501-.312.192-3.214c-1.184.524-2.844.899-3.145-.004v.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D5369"
                fillRule="evenodd"
                d="M94.79 96.637l2.2 18.921 3.01 18.899h7.56s-.682-3.286-.591-4.329c.091-1.042.435-10.931-1.837-15.482l.314-18.528-10.656.52z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0B384A"
                fillRule="evenodd"
                d="M105.185 114.646l.314-18.528-3.59.197-.23 13.569c-.044 2.528.284 5.068.887 7.566 1.192 4.937.943 11.818.868 12.678-.091 1.043.592 4.329.592 4.329h3.587s-.683-3.286-.592-4.329c.091-1.042.436-10.931-1.836-15.482z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                fillRule="evenodd"
                d="M128.183 135.047s-3.353-16.286-8.61-21.482l-6.067-21.611-9.6 3.804 21.675 40.276 2.602-.988v.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M125.588 135.557l-.361 2.089c-.091.53-1.414 2.396-1.414 2.396.113.493.612.43.943.263l5.153-3.942s1.012-1.131-1.89-2.631c-.624 1.11-1.615 2.354-2.432 1.825h.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D5369"
                fillRule="evenodd"
                d="M103.167 96.513l10.065 19.896 9.543 16.953 7.207-2.288s-1.811-2.9-2.093-3.913c-.281-1.013-3.445-10.466-7.218-14.079l-6.496-19.415-11.008 2.845v.001z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D5369"
                fillRule="evenodd"
                d="M110.264 81.526l-14.122 1.077c-2.297.844-1.38 14.087-1.38 14.087l9.286.43c4.314-.09 9.417 3.293 9.954-3.895.614-8.217-3.738-11.698-3.738-11.698z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0B384A"
                fillRule="evenodd"
                d="M114.374 94.167s-9.615.92-12.663-3.195l12.563-1.078s.291 3.224.102 4.273h-.002z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#002A3A"
                fillRule="evenodd"
                d="M96.493 65.44l4.903-.882 4.241-.424 6.882 2.077c1.848.843 1.339 2.565.705 4.501l.724 6.132c1.552 4.62 1.647 8.067 1.556 12.874-7.569 3.088-19.117 2.742-21.647-.695.103-9.62-.085-13.147 2.637-23.585v.002z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E99E88"
                d="M101.396 64.558s-1.565 4.259 1.327 4.352c2.893.093 4.333-4.327 4.333-4.327l-2.137-.87-3.522.845h-.001z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M112.76 73.567a.187.187 0 01.105.244l-.28.705 2 3.065a.188.188 0 11-.315.205l-2.053-3.146a.188.188 0 01-.017-.172l.316-.796a.188.188 0 01.244-.105zM95.72 69.508a.188.188 0 01.113.24l-.177-.063-.177-.063a.188.188 0 01.24-.114zm-.064.177l-.177-.063-.021.06-.056.157a79.973 79.973 0 00-.355 1.033 4.406 4.406 0 00-.109.365.188.188 0 00.369.076c.009-.044.044-.16.097-.324a36.153 36.153 0 01.352-1.025l.056-.157.021-.059-.177-.063z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#005E85"
                fillRule="evenodd"
                d="M62.726 43.93s.666-.732 1.622 1.145c.956 1.878 1.813 11.236 10.665 7.734l2.963-1.256-2.766-5.585s-5.654 5.823-8.901-1.83c-1.361-3.208-3.483-2.076-4.143-1.972l.56 1.762v.002zM83.461 40.292l-2.442 1.014.663 1.598 1.65-.686c.561-.232 1.244.13 1.525.81l1.153 2.779c.283.679.058 1.42-.503 1.651l-1.65.686.663 1.598 2.442-1.014c.85-.352 1.192-1.474.764-2.506l-1.95-4.7c-.429-1.03-1.464-1.581-2.314-1.227v-.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#005E85"
                fillRule="evenodd"
                d="M80.998 54.54l-4.18.924a5.028 5.028 0 01-5.995-3.827l-.183-9.866c-.006-.027.9-.754.927-.76l6.144-.852c-.184.286 3.2-.463 3.206-.436l3.908 8.823a5.028 5.028 0 01-3.827 5.996v-.002z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#005E85"
                d="M80.901 39.664c.146.709-2.03 1.772-4.86 2.377-2.831.605-5.245.52-5.391-.188-.146-.709 2.212-1.518 5.043-2.122 2.83-.605 5.062-.775 5.208-.067z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M102.077 60.753l1.022.25s-.166.823-.705.625c-.54-.197-.317-.875-.317-.875z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default SaveValueIcon;
